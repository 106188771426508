import React from 'react';

const UseDaily = (props: React.PropsWithChildren<React.SVGProps<SVGSVGElement>>) => (
  <svg
    {...props}
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="47.9"
    height="47.729"
    viewBox="0 0 47.9 47.729"
  >
    <defs>
      <clipPath>
        <rect width="47.9" height="47.729" fill="currentColor" />
      </clipPath>
    </defs>
    <g transform="translate(-1756.263 -691.938)">
      <g transform="translate(1696 586)">
        <g transform="translate(60.263 105.938)" clipPath="url(#clip-path)">
          <path
            d="M22.488,51.345q-.081,0-.163,0a21.4,21.4,0,0,1-8.4-2.133C6.5,45.626,1.971,39.809.465,31.92a23.289,23.289,0,0,1,5.645-20.4l.092-.106A3.74,3.74,0,0,1,8.155,4.565a3.265,3.265,0,0,1,.65.066c2.158.44,4.409.964,6.881,1.6a3.615,3.615,0,0,1,2.634,3.792c-.225,2.244-.482,4.432-.766,6.5a3.743,3.743,0,0,1-3.711,3.289,3.776,3.776,0,0,1-3.248-1.939,16.475,16.475,0,0,0,10.55,25.817c.281.048.574.084.857.118.36.044.732.089,1.1.161a3.721,3.721,0,0,1-.614,7.372M8.262,7.078A1.222,1.222,0,0,0,7.057,8,1.258,1.258,0,0,0,8.01,9.526c.095.026.191.047.287.069a4.1,4.1,0,0,1,.6.169,1.24,1.24,0,0,1,.73.741,1.208,1.208,0,0,1-.1,1.009,2.513,2.513,0,0,1-.443.523l-.067.066A20.909,20.909,0,0,0,2.508,26.675,21.791,21.791,0,0,0,8.6,42.441a21.373,21.373,0,0,0,13.446,6.4,2.177,2.177,0,0,0,.218.011A1.239,1.239,0,0,0,23.615,47.7c.064-.711-.495-1.263-1.361-1.341-.632-.057-1.2-.133-1.733-.232A18.968,18.968,0,0,1,7.007,18.994a18.484,18.484,0,0,1,3.527-4.879,1.425,1.425,0,0,1,.994-.438,1.156,1.156,0,0,1,1.112.834,1.973,1.973,0,0,1,.043.815l-.019.208a1.757,1.757,0,0,0,.145,1.195,1.272,1.272,0,0,0,1.05.586,1.11,1.11,0,0,0,.261-.031,1.319,1.319,0,0,0,1.006-1.3c.22-1.923.447-3.912.683-5.874a1.26,1.26,0,0,0-1.041-1.547c-1.974-.476-4-.95-6.2-1.447a1.4,1.4,0,0,0-.308-.035"
            transform="translate(0 -3.616)"
            fill="currentColor"
          />
          <path
            d="M123.009,46.672a3.684,3.684,0,0,1-.719-.071c-2.161-.43-4.328-.9-6.441-1.394a3.592,3.592,0,0,1-2.791-3.822c.1-1.37.244-2.756.388-4.1.061-.564.121-1.129.178-1.694l.02-.21c.027-.277.054-.564.1-.85a3.679,3.679,0,0,1,3.3-3.116c.132-.013.264-.02.395-.02a3.681,3.681,0,0,1,3.342,2.1A16.473,16.473,0,0,0,109.945,7.589c-.2-.029-.4-.049-.606-.069-.241-.023-.489-.047-.738-.088A3.736,3.736,0,0,1,109.19,0c.133,0,.27.007.4.019a22.413,22.413,0,0,1,7.859,2.109c7.924,3.856,12.533,10.138,13.7,18.672a23.152,23.152,0,0,1-5.945,19.117l-.026.029.022.017a3.752,3.752,0,0,1,1.178,4.448,3.686,3.686,0,0,1-3.375,2.261M117.423,33.9a1.2,1.2,0,0,0-1.225,1.079c-.253,2.242-.472,4.425-.653,6.49a1.153,1.153,0,0,0,.993,1.339c1.972.451,4.006.894,6.218,1.356a1.027,1.027,0,0,0,.207.021,1.277,1.277,0,0,0,1.184-.917,1.247,1.247,0,0,0-.766-1.477,3.067,3.067,0,0,0-.36-.1c-.121-.028-.245-.057-.373-.1a1.1,1.1,0,0,1-.866-1.217,2.151,2.151,0,0,1,.581-1.093,21.355,21.355,0,0,0,4.509-24.464A21.258,21.258,0,0,0,109.382,2.508,1.568,1.568,0,0,0,109.2,2.5a1.257,1.257,0,0,0-1.29,1.152A1.318,1.318,0,0,0,109.215,5a23.078,23.078,0,0,1,2.755.468,18.879,18.879,0,0,1,14.153,21.575A18.575,18.575,0,0,1,120.8,37.315a1.409,1.409,0,0,1-1.009.484,1.267,1.267,0,0,1-.528-.12,1.254,1.254,0,0,1-.7-1.428l.026-.222c.034-.286.065-.557.073-.827a1.207,1.207,0,0,0-1.121-1.3c-.038,0-.075,0-.113,0"
            transform="translate(-83.493)"
            fill="currentColor"
          />
          <path
            d="M120.6,94.037a1.217,1.217,0,0,1-1.211-1.3c-.009-.515-.007-1.037-.006-1.541q0-.4,0-.8c0-.026,0-.053,0-.079q-.082,0-.163,0l-2.759,0q-1.108,0-2.216,0h-.023a1.44,1.44,0,0,1-1.445-.763,1.5,1.5,0,0,1,.3-1.621c2.506-3.192,4.426-5.647,6.225-7.962a1.555,1.555,0,0,1,1.207-.707,1.4,1.4,0,0,1,1.374,1.6q-.007,2.451,0,4.9v2.057l.36,0c.154,0,.3,0,.454.009a1.244,1.244,0,1,1,0,2.487c-.088,0-.176,0-.265,0h-.554v1.725c0,.069,0,.138,0,.207,0,.177.005.361-.009.549a1.24,1.24,0,0,1-1.248,1.24Zm-1.245-6.254V83.962l-2.986,3.821Z"
            transform="translate(-89.224 -62.788)"
            fill="currentColor"
          />
          <path
            d="M65.269,94.664a1.444,1.444,0,0,1-1.477-.817,1.417,1.417,0,0,1,.394-1.622q.608-.626,1.213-1.256c.865-.9,1.76-1.826,2.657-2.724a7.284,7.284,0,0,0,2.006-2.8,2.06,2.06,0,0,0-1.894-2.907,3.171,3.171,0,0,0-.877.136,3.821,3.821,0,0,0-1.737,1.146,1.506,1.506,0,0,1-1.105.561,1.239,1.239,0,0,1-1.2-1.14,1.562,1.562,0,0,1,.48-1.124,5.714,5.714,0,0,1,4.287-2.07c.094,0,.189,0,.285.007a4.559,4.559,0,0,1,3.646,1.944,4.818,4.818,0,0,1,.472,4.28,8.487,8.487,0,0,1-2.2,3.332q-.754.744-1.516,1.48-.543.527-1.084,1.055l-.023.023h1.3c.827,0,1.631,0,2.436,0a2.432,2.432,0,0,1,.676.066,1.167,1.167,0,0,1,.9,1.286,1.186,1.186,0,0,1-1.084,1.124,2.058,2.058,0,0,1-.289.017H68.181q-1.446,0-2.891.005Z"
            transform="translate(-50.102 -63.404)"
            fill="currentColor"
          />
        </g>
      </g>
    </g>
  </svg>
);

export default UseDaily;
